import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import SEO from "../components/seo"

const StyleSheet = styled.div`
  margin-top: 20px;
  color: #666;
  & > p {
    line-height: 1.2;
    margin-bottom: 10px;
  }
`

const PrivacyPage = ({ data }) => {
  return (
    <div>
      <Layout>
        <SEO title="Privacy statement" />
        <StyleSheet>
          <ReactMarkdown 
            source={data.file.childMarkdownRemark.rawMarkdownBody}
            parserOptions={{commonmark:true}}
          />
        </StyleSheet>
      </Layout>
    </div>
  )
}

export default PrivacyPage

export const privacyPageQuery = graphql`
  query {
    file(name: {eq: "privacy"}) {
      childMarkdownRemark {
        rawMarkdownBody
        frontmatter {
          title
        }
      }
    }
  }
`
